import React from "react";
import { Avatar, Box, HStack, Image, Link, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { AuthModel } from "../../services/models";
import { APP_VERSION } from "../../constants";
export default function Topbar() {
  const user = AuthModel.getUser();

  return (
    <HStack
      justifyContent="space-between"
      alignItems="center"
      px={{ base: "3", lg: "6" }}
      height="80px"
    >
      <Box bgColor="white" rounded="full" py="1" px="2">
        <Image src="/images/logo2.svg" alt="" w="120px" />
      </Box>
      <Box display={"flex"} alignItems={"center"} gap={2}>
        <Text fontSize={'10px'}>{APP_VERSION}</Text>
        <Link as={NavLink} to="/profile" style={{ textDecoration: "none" }}>
          <HStack bgColor="white" rounded="full" py="1" pl="3" pr="2">
            <Box>
              <Text fontSize="sm" fontWeight="bold">
                {user.firstName + " " + user.lastName}
              </Text>
              <Text fontSize="xs">view profile</Text>
            </Box>
            <Avatar src="" size="sm" />
          </HStack>
        </Link>
      </Box>
    </HStack>
  );
}
