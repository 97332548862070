import React, { useEffect, useState } from "react";
import { ConfigModel } from "../services";
import {
  Button,
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { configSchema } from "../utils/schemas";
import { CInput } from "../components";
import { ProtectedLayout } from "../layouts";

export default function Configuration() {
  const [configs, setConfigs] = useState();
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const onChangeData = async (values) => {
    try {
      if (configs) {
        let body = { ...configs };
        body.value.username = values.username;
        body.value.password = values.password;
        await ConfigModel.update(body.key, body);
        setConfigs(body);
      } else {
        let body = {
          key: "slims",
          value: {
            username: values.username,
            password: values.password,
          },
        };
        await ConfigModel.create(body);
        setConfigs(body);
      }
      toast({
        description: "Configuration Saved successfully!",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: "Something went wrong!",
        status: "error",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        let response = await ConfigModel.getById("slims");
        setConfigs(response.data.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchConfigs();
  }, []);

  const formik = useFormik({
    initialValues: {
      username: configs?.value?.username || "",
      password: configs?.value?.password || "",
    },
    validationSchema: configSchema,
    onSubmit: onChangeData,
    enableReinitialize: true,
  });
  return (
    <ProtectedLayout>
      <Heading mb={2}>Slims Configuration</Heading>
      {loading ? (
        <Center my={3}>
          <Spinner />
        </Center>
      ) : (
        <>
          <SimpleGrid columns={{ sm: 1, md: 2 }} gap="3">
            <CInput
              label="Username"
              name="username"
              placeholder="Enter Username"
              value={formik.values.username}
              onChange={formik.handleChange}
              errors={formik.errors.username}
              touched={formik.touched.username}
            />
            <CInput
              label="Password"
              name="password"
              placeholder="Enter Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              errors={formik.errors.password}
              touched={formik.touched.password}
            />
          </SimpleGrid>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            isLoading={formik.isSubmitting}
            loadingText="Loading..."
            bgColor="black"
            borderRadius="lg"
            fontWeight="medium"
            py="6"
            px="7"
            mt="4"
          >
            Save
          </Button>
        </>
      )}
    </ProtectedLayout>
  );
}
