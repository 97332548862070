import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { TbProgressCheck } from "react-icons/tb";
import QuestionnaireModal from "./QuestionnaireModal";
import ReportTypeModal from "./ReportTypeModal";

export default function UpdateStatusModal({ data, onUpdateStatus }) {
  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure();
  const {
    isOpen: isOpenReportType,
    onOpen: onOpenReportType,
    onClose: onCloseReportType,
  } = useDisclosure();
  const statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Received", value: "received" },
    { label: "Analysed", value: "analysed" },
  ];
  const [currentValue, setCurrentValue] = useState(data.status);
  const currentIndex = statusOptions.findIndex(
    (item) => item.value === data.status
  );

  return (
    <>
      <Tooltip label="Update status">
        <IconButton
          bgColor="primary.100"
          color="primary.main"
          fontSize="xl"
          icon={<TbProgressCheck />}
          isRound
          onClick={onOpenStatus}
        />
      </Tooltip>
      <Modal
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        size="xl"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="2" maxHeight={'400px'} overflowY={'auto'}>
              {statusOptions.map((item, i) => {
                return (
                  <Box key={i}>
                    <Checkbox
                      size="lg"
                      variant="circular"
                      colorScheme="green"
                      isChecked={
                        item.value === currentValue || i <= currentIndex
                      }
                      isDisabled={i <= currentIndex || i > currentIndex + 1}
                      onChange={(e) =>
                        e.target.checked
                          ? setCurrentValue(item.value)
                          : setCurrentValue(data.status)
                      }
                    >
                      {item.label}
                    </Checkbox>
                    {i + 1 < statusOptions.length && (
                      <Divider orientation="vertical" height="25px" ml="10px" />
                    )}
                  </Box>
                );
              })}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                if (currentValue === statusOptions[1].value) {
                  onOpenForm();
                } else if (currentValue === statusOptions[2].value) {
                  onOpenReportType();
                } else {
                  onUpdateStatus(data, currentValue);
                  onCloseStatus();
                }
              }}
              isDisabled={currentValue === data.status}
              bgColor="black"
              borderRadius="lg"
              fontWeight="medium"
              py="6"
              px="7"
              me="2"
            >
              Update
            </Button>
            <Button
              onClick={() => onCloseStatus()}
              bgColor="gray.200"
              color="black"
              borderRadius="lg"
              fontWeight="medium"
              py="6"
              px="7"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <QuestionnaireModal
        data={data}
        onUpdateStatus={onUpdateStatus}
        statusOptions={statusOptions}
        isOpenForm={isOpenForm}
        onCloseForm={onCloseForm}
        onCloseStatus={onCloseStatus}
      />
      <ReportTypeModal
        data={data}
        onUpdateStatus={onUpdateStatus}
        statusOptions={statusOptions}
        isOpenReportType={isOpenReportType}
        onCloseReportType={onCloseReportType}
        onCloseStatus={onCloseStatus}
      />
    </>
  );
}
