import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../layouts";
import {
  Badge,
  Button,
  HStack,
  Heading,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { BiLink, BiFile } from "react-icons/bi";
import ViewCOCModal from "../components/all-cocs-screen/ViewCOCModal";
import {
  CInput,
  CSelect,
  DeleteCOCModal,
  DetailedAnalysisOrderModal,
  DetailedAnalysisReportModal,
  ExportToExcelModal,
  UpdateStatusModal,
} from "../components";
import {
  AuthModel,
  CocDeleteRequestModel,
  CocModel,
  FileModel,
} from "../services/models";
import { useNavigate } from "react-router-dom";
import { FaRegCircleQuestion, FaRegFilePdf } from "react-icons/fa6";
import { exportExcel } from "../utils/exportExcel";
import { ASSET_URL } from "../services/axios";
import { formatDate } from "../utils";
import { LuFileLineChart, LuFileUp } from "react-icons/lu";

export default function AllCOCs() {
  const user = AuthModel.getUser();
  const [pending, setPending] = useState(true);
  const [cocs, setCocs] = useState([]);
  const [filteredCocs, setFilteredCocs] = useState([]);
  const [searchLocation, setSearchLocation] = useState("");
  const [searchStatus, setSearchStatus] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Received", value: "received" },
    { label: "Analysed", value: "analysed" },
    { label: "DA Requested", value: "da-requested" },
    { label: "DA Completed", value: "da-completed" },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "red";
      case "received":
        return "blue";
      case "analysed":
        return "green";
      case "pending-deletion":
        return "yellow";
      case "da-requested":
        return "red";
      case "da-completed":
        return "green";
      default:
        return "red";
    }
  };

  const customStyles = {
    headRow: {
      style: {
        borderBottomWidth: "0px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        borderRadius: "15px",
        boxShadow: "1px 2px 6px 0px rgb(0,0,0,0.1)",
        marginBottom: "10px",
        "&:not(:last-of-type)": {
          borderBottomWidth: "0px",
        },
      },
    },
    pagination: {
      style: {
        borderTopWidth: "0px",
      },
    },
  };
  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.clientName,
    },
    {
      name: "Report Status",
      cell: (row) => {
        return (
          <Badge
            colorScheme={getStatusColor(row.status)}
            rounded="full"
            textTransform="capitalize"
            px="1.5"
            pb="0.5"
          >
            {row.status === statusOptions[3].value
              ? statusOptions[3].label
              : row.status === statusOptions[4].value
              ? statusOptions[4].label
              : row.status}
          </Badge>
        );
      },
    },
    {
      name: "Client Email",
      selector: (row) => row.email,
    },
    {
      name: "Testing Location",
      selector: (row) =>
        row.propertyType !== "vehicle"
          ? row.address +
            ", " +
            row.city +
            ", " +
            row.state +
            ", " +
            row.postcode
          : "Vehicle " + row.VIN + ", " + row.rego + ", " + row.regoState,
    },
    {
      name: "Date Sampled",
      selector: (row) => formatDate(row.dateSampled),
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <HStack>
            <ViewCOCModal
              data={row}
              userRole={user.role}
              prepareOrderExcelData={prepareOrderExcelData}
              prepareSampleExcelData={prepareSampleExcelData}
              refetchData={fetchCocs}
            />
            {row.status !== "pending-deletion" && (
              <>
                {
                  (row.status !== statusOptions[0] ||
                    row.status !== statusOptions[1]) &&
                  row.orderFiles.length &&
                  row.orderFiles.find((obj) => obj.fileName === "pdfFile") ? (
                    <Tooltip label="Download report">
                      <IconButton
                        onClick={() =>
                          downloadPDF(
                            ASSET_URL +
                              row.orderFiles.find(
                                (obj) => obj.fileName === "pdfFile"
                              )?.fileURL
                          )
                        }
                        bgColor="primary.100"
                        color="red.400"
                        fontSize="xl"
                        icon={<FaRegFilePdf />}
                        isRound
                      />
                    </Tooltip>
                  ) : null
                  // (row.status === "analysed" ||
                  //   (user.role === "admin" && row.status !== "pending")) && (
                  //   <Tooltip label="View report">
                  //     <IconButton
                  //       onClick={() => navigate(`/report/${row._id}`)}
                  //       bgColor="primary.100"
                  //       color="primary.main"
                  //       fontSize="xl"
                  //       icon={<BiFile />}
                  //       isRound
                  //     />
                  //   </Tooltip>
                  // )
                }
                {(row.status === statusOptions[0].value ||
                  row.status === statusOptions[1].value) &&
                  user.role === "admin" && (
                    <UpdateStatusModal
                      data={row}
                      onUpdateStatus={onUpdateStatus}
                    />
                  )}
                {/* {row.status === "pending" && user.role !== "admin" && (
                  <DeleteCOCModal data={row} onDelete={onDelete} />
                )} */}
                {/* {row.status === "analysed" &&
                  user.role === "admin" &&
                  row.reportType === "manual" && (
                    <Tooltip label="Copy report link">
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            window.location.host + `/report/${row._id}`
                          );
                          toast({
                            description: "Link Copied",
                            status: "success",
                            isClosable: true,
                          });
                        }}
                        bgColor="primary.100"
                        color="primary.main"
                        fontSize="xl"
                        icon={<BiLink />}
                        isRound
                      />
                    </Tooltip>
                  )} */}
                {/* {(row.status === "received" || row.status === "analysed") &&
                  user.role === "admin" && (
                    <ExportToExcelModal
                      data={row}
                      prepareOrderExcelData={prepareOrderExcelData}
                      prepareSampleExcelData={prepareSampleExcelData}
                    />
                  )} */}
                {row.status === "analysed" &&
                row.samplingFor === "laboratory-composite-order" &&
                row.orderFiles.length &&
                !row.orderFiles.find((obj) => obj.fileName === "DAFile") &&
                user.role !== "admin" ? (
                  <Tooltip
                    label={
                      row.status === statusOptions[3].value
                        ? "Requested"
                        : "Request DA Order"
                    }
                  >
                    <Button
                      onClick={() =>
                        onUpdateStatus(row, statusOptions[3].value)
                      }
                      bgColor="primary.main"
                      color="primary.100"
                      size="xs"
                      leftIcon={<FaRegCircleQuestion />}
                      isDisabled={row.status === statusOptions[3].value}
                      rounded="full"
                    >
                      Request DA
                    </Button>
                  </Tooltip>
                ) : null}
                {row.status === statusOptions[3].value &&
                row.samplingFor === "laboratory-composite-order" &&
                user.role === "admin" ? (
                  <DetailedAnalysisOrderModal
                    data={row}
                    statusOptions={statusOptions}
                    onUpdateStatus={onUpdateStatus}
                  />
                ) : null}
                {row.status === statusOptions[4].value &&
                row.samplingFor === "laboratory-composite-order" ? (
                  <DetailedAnalysisReportModal
                    data={row.orderFiles.find(
                      (obj) => obj.fileName === "DAFile"
                    )}
                  />
                ) : null}
              </>
            )}
          </HStack>
        );
      },
    },
  ];

  const onUpdateStatus = async (data, value) => {
    try {
      let todaysDate = new Date();
      let newData = [...cocs];
      let foundIndex = cocs.findIndex((item) => item._id === data._id);
      newData[foundIndex].status = value;
      if (value === statusOptions[1].value) {
        newData[foundIndex].dateReceived = todaysDate;
        newData[foundIndex].questions = [...data.questions];
      }
      if (value === statusOptions[2].value) {
        newData[foundIndex].dateAnalysed = todaysDate;
        //upload files
        for (let i = 0; i < data.orderFiles.length; i++) {
          if (data.orderFiles[i].fileURL instanceof File) {
            let response = await FileModel.upload({
              file: data.orderFiles[i].fileURL,
            });
            if (response.status === 200) {
              newData[foundIndex].orderFiles.push({
                ...data.orderFiles[i],
                fileURL: response.data.fileURL,
              });
            } else {
              throw new Error("File upload failed!");
            }
          }
        }
      }
      if (value === statusOptions[4].value) {
        let dafile = data.orderFiles.find((obj) => obj.fileName === "DAFile");
        if (dafile && dafile?.fileURL instanceof File) {
          let response = await FileModel.upload({
            file: dafile.fileURL,
          });
          if (response.status === 200) {
            newData[foundIndex].orderFiles.push({
              fileName: dafile.fileName,
              fileURL: response.data.fileURL,
            });
          } else {
            throw new Error("File upload failed!");
          }
        }
      }

      let response = await CocModel.update(data._id, newData[foundIndex]);
      if (response.status === 200) {
        newData = [...cocs];
        foundIndex = cocs.findIndex((item) => item._id === data._id);
        newData[foundIndex] = response.data.data;
        setCocs(newData);
        newData = [...filteredCocs];
        foundIndex = filteredCocs.findIndex((item) => item._id === data._id);
        newData[foundIndex] = response.data.data;
        setFilteredCocs(newData);
        toast({
          description: "Status update successfully",
          status: "success",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: "Something went wrong",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  };

  // const onDelete = async (values) => {
  //   try {
  //     let response = await CocDeleteRequestModel.create(values);
  //     let coc = cocs.find((item) => item._id === values.coc);
  //     response = await CocModel.update(coc._id, {
  //       ...coc,
  //       status: "pending-deletion",
  //     });
  //     if (response.status === 200) {
  //       let newData = [...cocs];
  //       let foundIndex = cocs.findIndex((item) => item._id === values.coc);
  //       newData[foundIndex].status = "pending-deletion";
  //       setCocs(newData);
  //       newData = [...filteredCocs];
  //       foundIndex = filteredCocs.findIndex((item) => item._id === values.coc);
  //       newData[foundIndex].status = "pending-deletion";
  //       setFilteredCocs(newData);
  //     }
  //     toast({
  //       description: "Deletion Requested successfully",
  //       status: "success",
  //       isClosable: true,
  //     });
  //   } catch (error) {
  //     toast({
  //       description: "Something went wrong",
  //       status: "error",
  //       isClosable: true,
  //     });
  //   }
  // };

  const prepareOrderExcelData = (data) => {
    let comments = data.questions
      .filter((item, i) => (i === 4 && item.answer) || item.answer === false)
      .map((item) => item.comment)
      .toString();

    let newData = {
      "Client Name": data.clientName,
      "Contact Name": data.contactName,
      Phone: data.phone.replace(/\s/g, ""),
      "E-mail": data.email,
      "Sampling For": data.samplingFor,
      "Date Sampled": data.dateSampled,
      "Sampling Technique": data.samplingTechnique,
      "Security Seal No": data.securitySealNo,
      "Property Type": data.propertyType,
    };
    if (data.propertyType !== "vehicle") {
      newData["Address"] = data.address;
      newData["City"] = data.city;
      newData["State"] = data.state;
      newData["Postcode"] = data.postcode;
    } else {
      newData["Registration Number"] = data.rego;
      newData["State of Registration"] = data.regoState;
      newData["VIN Number"] = data.VIN;
    }
    newData["Comments"] = comments;
    newData["Order Type (rdtp_name)"] = data.samplingFor.split("-").join(" ");
    newData["Status"] = "Pending";
    let arrayData = [];
    arrayData.push(newData);
    let reportName =
      data.propertyType !== "vehicle"
        ? data.address +
          ", " +
          data.city +
          ", " +
          data.state +
          ", " +
          data.postcode
        : data.VIN;
    exportExcel(arrayData, reportName + "-report");
  };

  const prepareSampleExcelData = (data) => {
    let newData = [];

    for (let i = 0; i < data.samples.length; i++) {
      if ((i + 1) % 20 === 1) {
        if (i === 0) {
          let obj = {};
          if (data.propertyType !== "vehicle") {
            obj["Address"] = data.address;
          } else {
            obj["VIN Number"] = data.VIN;
          }
          obj["Sample Location"] = "Field Blank";
          obj["Sample Number"] = "Field Blank";
          obj["Status (stts_name)"] = "Pending";
          newData.push(obj);
        } else {
          let obj = {};
          if (data.propertyType !== "vehicle") {
            obj["Address"] = data.address;
          } else {
            obj["VIN Number"] = data.VIN;
          }
          obj["Sample Location"] = data.samples[i].value;
          obj["Sample Number"] = i + 1;
          obj["Status (stts_name)"] = "Pending";
          newData.push(obj);
        }
        let obj = {};
        if (data.propertyType !== "vehicle") {
          obj["Address"] = data.address;
        } else {
          obj["VIN Number"] = data.VIN;
        }
        obj["Sample Location"] = data.samples[i].value;
        obj["Sample Number"] = i + 1;
        obj["Status (stts_name)"] = "Pending";
        newData.push(obj);
      } else {
        let obj = {};
        if (data.propertyType !== "vehicle") {
          obj["Address"] = data.address;
        } else {
          obj["VIN Number"] = data.VIN;
        }
        obj["Sample Location"] = data.samples[i].value;
        obj["Sample Number"] = i + 1;
        obj["Status (stts_name)"] = "Pending";
        newData.push(obj);
      }
    }
    let reportName =
      data.propertyType !== "vehicle"
        ? data.address +
          ", " +
          data.city +
          ", " +
          data.state +
          ", " +
          data.postcode
        : data.VIN;
    exportExcel(newData, reportName + "-report");
  };

  const downloadPDF = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const fetchCocs = async () => {
    try {
      let response;
      if (user.role !== "admin") {
        response = await CocModel.getByUser(user._id);
      } else {
        response = await CocModel.get();
      }
      setCocs(response.data.data);
      setFilteredCocs(response.data.data);
    } catch (error) {
      toast({
        description: "Something went wrong!",
        status: "error",
        isClosable: true,
      });
    } finally {
      setPending(false);
      setTimeout(() => fetchCocs(), 60 * 1000);
    }
  };

  useEffect(() => {
    fetchCocs();
  }, []);

  const filterMethods = [
    (item) =>
      (item.propertyType !== "vehicle"
        ? item.address +
          ", " +
          item.city +
          ", " +
          item.state +
          ", " +
          item.postcode
        : "Vehicle " + item.VIN + ", " + item.rego + ", " + item.regoState
      )
        .toLowerCase()
        .indexOf(searchLocation?.toLowerCase()) != -1,
    (item) => (searchStatus !== null ? item.status === searchStatus : true),
  ];

  useEffect(() => {
    if (searchLocation || searchStatus) {
      let searchedCocs = cocs.filter((item) => {
        for (let i = 0; i < filterMethods.length; i++) {
          if (!filterMethods[i](item)) {
            return false;
          }
        }
        return true;
      });
      setFilteredCocs(searchedCocs);
    } else {
      setFilteredCocs(cocs);
    }
  }, [searchLocation, searchStatus]);

  return (
    <ProtectedLayout>
      <HStack
        justifyContent="space-between"
        alignItems={{ base: "start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        mb="2"
      >
        <Heading>All Orders</Heading>
        <HStack
          gap="2"
          minW={{ base: "100%", md: "xl" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <CInput
            placeholder="Search Testing Location"
            value={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
          />
          <CSelect
            placeholder="Report Status"
            options={statusOptions}
            onChange={(opt) => setSearchStatus(opt ? opt.value : null)}
            isClearable={true}
          />
        </HStack>
      </HStack>
      <DataTable
        columns={columns}
        data={filteredCocs}
        progressPending={pending}
        keyField="_id"
        customStyles={customStyles}
        responsive
        pagination
      />
    </ProtectedLayout>
  );
}
