import React, { useState } from "react";
import { useFormik } from "formik";
import { questionnaireSchema } from "../../utils/schemas";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { CInput } from "../common";
import { formatDate } from "../../utils";

export default function QuestionnaireModal({
  data,
  onUpdateStatus,
  statusOptions,
  isOpenForm,
  onCloseForm,
  onCloseStatus,
}) {
  const [checkbox, setCheckbox] = useState();
  const questions = [
    "1. Security Seal Number",
    "2. Sampling Address",
    "3. Sampling Date",
    "4. Number of Samples",
    "5. Sampling Locations",
    "6. Are there any discrepancies and/or visible damage/leakage? If Yes - Please record them in the Certificate of Analysis Comments section.",
  ];
  const testTypes = [
    { label: "Validation Analysis Order", value: "validation-analysis-order" },
    {
      label: "Laboratory Composite Order",
      value: "laboratory-composite-order",
    },
    { label: "Detailed Analysis Order", value: "detailed-analysis-order" },
  ];
  var blankCount = 0;

  const formik = useFormik({
    initialValues: {
      ...data,
      questions: questions.map(() => ({ answer: null, comment: "" })),
    },
    validationSchema: questionnaireSchema,
    onSubmit: (values) => {
      onUpdateStatus(values, statusOptions[1].value);
      onCloseForm();
      onCloseStatus();
    },
  });
  return (
    <Modal
      isOpen={isOpenForm}
      onClose={onCloseForm}
      size="5xl"
      // scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sample Reception Checklist</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Flex flexDirection="row" height={'500px'} px="30px">
            {/* Left Column */}
            <Box
              pe={5}
              width="50%"
              height="100%"
              overflowY="auto"
            >
              <Heading fontSize="lg" mb={3}>
                Digital Chain of Custody Form
              </Heading>
              <Box>
                <SimpleGrid columns={2} spacing={3} mb="3">
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Company Name
                    </Heading>
                    <Text>{data.clientName !== "" ? data.clientName : "N/A"}</Text>
                  </Box>
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Contact Name
                    </Heading>
                    <Text>{data.contactName}</Text>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={2} spacing={3} mb="3">
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Client Email
                    </Heading>
                    <Text>{data.email}</Text>
                  </Box>
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Phone
                    </Heading>
                    <Text>{data.phone !== "" ? data.phone : "N/A"}</Text>
                  </Box>
                </SimpleGrid>
              </Box>
              <Divider borderColor="primary.main" my="3" />
              <Box>
                <SimpleGrid columns={2} spacing={3} mb="3">
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Date Sampled
                    </Heading>
                    <Text>{formatDate(data.dateSampled)}</Text>
                  </Box>
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Order Type
                    </Heading>
                    <Text>
                      {testTypes.find((opt) => opt.value === data.samplingFor).label}
                    </Text>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={2} spacing={3} mb="3">
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      {data.propertyType !== "vehicle" ? "Property Address" : "Vehicle Details"}
                    </Heading>
                    <Text>
                      {data.propertyType !== "vehicle"
                        ? `${data.address}, ${data.city}, ${data.state}, ${data.postcode}`
                        : `${data.VIN}, ${data.rego}, ${data.regoState}`}
                    </Text>
                  </Box>
                  <Box>
                    <Heading as="h6" fontSize="sm">
                      Sampling Technique
                    </Heading>
                    <Text>{data.samplingTechnique !== "" ? data.samplingTechnique : "N/A"}</Text>
                  </Box>
                </SimpleGrid>
              </Box>
              <Divider borderColor="primary.main" my="3" />
              <Heading as="h6" fontSize="sm" mb="3">
                Samples
              </Heading>
              <TableContainer>
                <Table variant="striped" borderWidth="1px" mb="2">
                  <Thead>
                    <Tr>
                      <Th textAlign="center">Client Reference Number</Th>
                      <Th textAlign="center">Sampling Location</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.samples.map((item, i) => {
                      if ((i + 1) % 20 === 1) {
                        blankCount++;
                        return (
                          <React.Fragment key={i}>
                            <Tr>
                              <Td py="2" textAlign="center">{"FB" + blankCount}</Td>
                              <Td py="2" textAlign="center">Field Blank</Td>
                            </Tr>
                            <Tr>
                              <Td py="2" textAlign="center">{i + 1}</Td>
                              <Td py="2" textAlign="center">{item.value}</Td>
                            </Tr>
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <Tr key={i}>
                            <Td py="2" textAlign="center">{i + 1}</Td>
                            <Td py="2" textAlign="center">{item.value}</Td>
                          </Tr>
                        );
                      }
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>

            {/* Right Column */}
            <Box
              ps={5}
              borderStart="1px solid"
              borderColor="gray.200"
              width="50%"
              height="100%"
              overflowY="auto"
              pr={'1rem'}
            >
              <Heading fontSize="lg" mb={3}>
                Sample Reception Checklist
              </Heading>
              <Text mb={3}>
                Please verify that the following information on the sample tubes
                and the security-sealed bag matches the information stated on
                the Digital Chain of Custody Form:
              </Text>
              {questions.map((item, i) => (
                <VStack key={i} alignItems="start" mb={3}>
                  <Text>{item}</Text>
                  <RadioGroup value={formik.values.questions[i].answer}>
                    <HStack gap={8}>
                      <Radio
                        value={true}
                        onChange={(e) => {
                          if (e.target.checked) {
                            let temp = [...formik.values.questions];
                            temp[i].answer = true;
                            formik.setFieldValue("questions", temp);
                          }
                        }}
                        colorScheme="yellow"
                      >
                        Yes
                      </Radio>
                      <Radio
                        value={false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            let temp = [...formik.values.questions];
                            temp[i].answer = false;
                            formik.setFieldValue("questions", temp);
                          }
                        }}
                        colorScheme="yellow"
                      >
                        No
                      </Radio>
                    </HStack>
                  </RadioGroup>
                  {formik.touched.questions?.[i]?.answer &&
                    formik.errors.questions?.[i]?.answer && (
                      <Text color="red.500" fontSize="sm">
                        {formik.errors.questions?.[i]?.answer}
                      </Text>
                    )}
                  {i < 5
                    ? formik.values.questions[i].answer !== null &&
                    !formik.values.questions[i].answer && (
                      <CInput
                        type="textarea"
                        label="Comment"
                        name={`questions.${i}.comment`}
                        placeholder="Enter Comment"
                        value={formik.values.questions[i].comment}
                        onChange={(e) => {
                          let temp = [...formik.values.questions];
                          temp[i].comment = e.target.value;
                          formik.setFieldValue("questions", temp);
                        }}
                        errors={formik.errors.questions?.[i]?.comment}
                        touched={formik.touched.questions?.[i]?.comment}
                      />
                    )
                    : formik.values.questions[i].answer !== null &&
                    formik.values.questions[i].answer && (
                      <CInput
                        type="textarea"
                        label="Comment"
                        name={`questions.${i}.comment`}
                        placeholder="Enter Comment"
                        value={formik.values.questions[i].comment}
                        onChange={(e) => {
                          let temp = [...formik.values.questions];
                          temp[i].comment = e.target.value;
                          formik.setFieldValue("questions", temp);
                        }}
                        errors={formik.errors.questions?.[i]?.comment}
                        touched={formik.touched.questions?.[i]?.comment}
                      />
                    )}
                </VStack>
              ))}
              <Checkbox
                value={checkbox}
                onChange={(e) => setCheckbox(e.target.checked)}
                colorScheme="yellow"
              >
                As a member of the laboratory staff, I confirm that I have
                followed the Sample Reception Protocol Checklist and accepted
                and/or rejected the IDOW Order for analysis.
              </Checkbox>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            isDisabled={!checkbox}
            bgColor="black"
            borderRadius="lg"
            fontWeight="medium"
            py="4"
            px="7"
            me="2"
          >
            Submit
          </Button>
          <Button
            onClick={() => onCloseForm()}
            bgColor="gray.200"
            color="black"
            borderRadius="lg"
            fontWeight="medium"
            py="4"
            px="7"
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
